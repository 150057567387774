.App {
  text-align: center;
  font-family: "Spoqa Han Sans";

  height: 100%;
}

.App-left {
  text-align: left;
}

.App-right {
  text-align: right;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.box {
  width: 150px;
  height: 150px;
  background-color: #cccccc;
  float: left;
  margin-right: 10px;}

.sc {
  overflow: scroll;
}

.color-nav {
  background: linear-gradient(100deg, #0b3885, #1155cc);
  border: linear-gradient(100deg, #0b3885, #1155cc);
}

.color-nav-l {
  background: linear-gradient(100deg, #2c0b85, #5211cc);
  border: linear-gradient(100deg, #2c0b85, #5211cc);
}

.Growing-theme {
  background: #1155cc;
  color: white;
  font-size: larger;
}

.Growing-button {
  background: #1155cc;
  color: white;
  border: #1155cc 1px solid;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.padd {
  padding-top: 10px;
}

.Page-header {
  margin-top: 5rem;
  margin-bottom: 0;
  margin-left: 3rem;
  text-align: left;
  font-size: 40px;
  background: linear-gradient(30deg, #0b3885, #1155cc);
  color: ghostwhite;
}
.Page-header-sub {
  margin-top: 6rem;
  margin-bottom: 0;
  margin-left: 3rem;
  text-align: left;
  font-size: 30px;
  background: linear-gradient(30deg, #0b3885, #1155cc);
  color: ghostwhite;
}

.Date-picker-a {
  border: 1px solid rgb(207, 212, 217);
  width: 100%;
  height: 58px;
  border-radius: 4px;
  text-indent: 10px;
}

.Date-picker-border {
  border: 1px solid rgb(207, 212, 217);
  width: 100%;
  height: 38px;
  border-radius: 4px;
  text-align: center;
}

.Title-font {
  font-size: 38px;
}

.Growing-font {
  color: #1155cc;
}

.center-wrap {
  display: grid;
  place-items: center;
  min-height: 40vh;
}

.Word-font {
  color: #222529;
}

.center-wrap2 {
  margin: 1vh;
}

.Table-Border {
  background-color: #081f47;
  color: whitesmoke;
}

.verticalLine {
  border-left: 1px solid rgb(207, 212, 217);
}

.outer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@font-face {
  font-family: "Orbitron";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/orbitron/v19/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nyGy6BoWg1fDAlp7lk.woff)
    format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Spoqa Han Sans";
  font-weight: 200;
  src: url("./lib/assets/fonts/SpoqaHanSansNeo-Thin.woff2") format("woff2"),
    url("./lib/assets/fonts/SpoqaHanSansNeo-Thin.woff") format("woff"),
    url("./lib/assets/fonts/SpoqaHanSansNeo-Thin.ttf") format("truetype"),
    url("./lib/assets/fonts/SpoqaHanSansNeo-Thin.eot") format("eot"),
}
@font-face {
  font-family: "Spoqa Han Sans";
  font-weight: 300;
  src: url("./lib/assets/fonts/SpoqaHanSansNeo-Light.woff2") format("woff2"),
    url("./lib/assets/fonts/SpoqaHanSansNeo-Light.woff") format("woff"),
    url("./lib/assets/fonts/SpoqaHanSansNeo-Light.ttf") format("truetype"),
    url("./lib/assets/fonts/SpoqaHanSansNeo-Light.eot") format("eot");
}
@font-face {
  font-family: "Spoqa Han Sans";
  font-style: normal;
  font-weight: 400;
  src: url("./lib/assets/fonts/SpoqaHanSansNeo-Regular.woff2") format("woff2"),
    url("./lib/assets/fonts/SpoqaHanSansNeo-Regular.woff") format("woff"),
    url("./lib/assets/fonts/SpoqaHanSansNeo-Regular.ttf") format("truetype"),
    url("./lib/assets/fonts/SpoqaHanSansNeo-Regular.eot") format("eot");
}
@font-face {
  font-family: "Spoqa Han Sans";
  font-weight: 500;
  src: url("./lib/assets/fonts/SpoqaHanSansNeo-Bold.woff2") format("woff2"),
    url("./lib/assets/fonts/SpoqaHanSansNeo-Bold.woff") format("woff"),
    url("./lib/assets/fonts/SpoqaHanSansNeo-Bold.ttf") format("truetype"),
    url("./lib/assets/fonts/SpoqaHanSansNeo-Bold.eot") format("eot");
}

.both-resize {
  resize: both;
}

.card-blueberry {
  background: linear-gradient(30deg, #164bf8, #4b82fa);
  border: honeydew;
  color: honeydew;
}

.card-guaba {
  background: linear-gradient(30deg, #f71f10, #f8633e);
  border: honeydew;
  color: honeydew;
}

.card-mango {
  background: linear-gradient(30deg, #f89602, #fab319);
  border: honeydew;
  color: rgb(252, 248, 248);
}

.card-green {
  background: linear-gradient(30deg, #06a001, #57c453);
  border: honeydew;
  color: honeydew;
}

.card-light {
  background: #fafafa;
  border: none;
  color: #181818;
}

.card-bora {
  background: linear-gradient(30deg, #9b22fd, #d376ff);
  border: honeydew;
  color: honeydew;
}

.card-dark {
  background: linear-gradient(30deg, #131313, #414140);
  border: honeydew;
  color: honeydew;
}

.Form-label {
  text-align: left;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.Required-Text {
  text-align: left;
  color: red;
  font-size: 0.8rem;
}

.footer {
  height: 100px;
  display: flex;
  align-items: end;
  margin-top: auto;
  font-size: 8px;
  color: gray;
}

.contents {
  width: 96%;
  max-width: 1100px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.card-border {
  border: solid 2px rgb(143, 143, 143);
}

.competition-label {
  background: linear-gradient(30deg, #306df1, rgb(49, 107, 242));
  border: honeydew;
  color: honeydew;
}

.competition-line {
  background: linear-gradient(30deg, #f4fafd, #e3edfa);
  border: honeydew;
  color: honeydew;
}

.competition-sum {
  background: linear-gradient(30deg, #131313, #b0c4de);
  border: honeydew;
  color: honeydew;
}

.competition-win {
  background: linear-gradient(30deg, #131313, #414140);
  border: honeydew;
  color: honeydew;
}

.competition-lose {
  background: linear-gradient(30deg, #131313, #414140);
  border: honeydew;
  color: honeydew;
}

.carousel__image {
  position: relative;
  display: inline-block;
}

.carousel__image::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 20px,
    #465298 20px,
    #465298 30px
  );
  pointer-events: none;
  content: "";
}

/** if you want the header above the gradient **/
.carousel__image h1 {
  position: relative;
  z-index: 1;
}

.page {
  padding: 0.5cm 1cm;
}

@page {
  margin-top: 0;
}

@page :first {
  margin-top: -1.5cm;
}

.list {
  min-height: 20cm;
}

.question {
  padding: 1.5cm 0cm 2.5cm 0cm;
  text-align: left;
}

@media print {
  .page {
    margin: 1cm 1.5cm 0cm 1.5cm;
    border: thick double black;
    border-radius: initial;
    background: initial;
    page-break-before: always;
    display: block;
  }

  .list {
    margin: 0cm 1.5cm 0cm 1.5cm;
    column-fill: auto;
    break-inside: avoid-column;
    page-break-inside: auto;
    column-count: 2;
    column-gap: 40px;
  }

  .question {
    page-break-inside: avoid;
  }
}

.article {
  border: 1px solid grey;
  text-align: left;
  padding: 5px 10px 5px 10px;
  margin: 5px 0px;
  border-radius: 3px;
}

.passage-p {
  cursor: pointer;
  display: inline;
}
.passage-p:hover {
  background-color: lightgray;
}
.passage-activep {
  cursor: pointer;
  display: inline;
  background-color: yellowgreen;
}
.passage-activep:hover {
  background-color: rgb(127, 161, 58);
}
.passage-activep-noteng {
  cursor: pointer;
  display: inline;
  background-color: yellowgreen;
  color: yellowgreen;
}
.passage-activep-noteng:hover {
  background-color: rgb(127, 161, 58);
  color: rgb(127, 161, 58);
}