.display-answer {
    cursor: pointer;
    -ms-user-select: none; 
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;

    &:hover {
        background-color: rgb(43, 43, 43, 0.9);    
    }
}