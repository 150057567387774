.growing-navbar {
    border-bottom: 2px solid #1155cc;
    background-color: white;

    .logout {
        position: absolute;
        bottom: 0;
    }
    
    .nav-title {
        color: #1155cc;
    
        &:hover {
            color: #0b3885;
        }
        &:visited {
            color: #1155cc;
        }
    }
    
    .nav-link {
        color: black;
    
        &:hover {
            color: #0b3885;
        }
        &:visited {
            color: black;
        }
    }

    img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
    }

    .text-title {
        font-size: 16px;
        color: #1155cc;
    
        &:hover {
            color: #6c8dc3;
        }
        &:visited {
            color: #1155cc;
        }
    }
}



