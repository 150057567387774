.board-content {
    text-align: left;

    .write-log {
        color: grey;
        text-align: right;
    }
}

.board-date {
    height: 20px;
}