@font-face {
  font-family: "Pretendard-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("./Pretendard-Bold.otf") format("openType");
}

@font-face {
  font-family: "Pretendard-Regular";
  font-style: normal;
  font-weight: normal;
  src: url("./Pretendard-Regular.otf") format("woff");
}
