.InputTable {
    tr {
        vertical-align: middle;
    }

    .add {
        background-color: skyblue
    }
    .notadd {
        background-color: grey
    }
    .delete {
        background-color: salmon
    }
    .modify {
        background: darkkhaki
    }
    .danger {
        background-color: red;
    }
    .none {
        background-color: none
    }

    .pointer {
        cursor: pointer
    }
}

.board-table {
    tr {
        vertical-align: middle;
        cursor: pointer;
    }
    .notice {
        font-weight: bold;
    }
    .title {
        text-align: left;
    }
}

.file-table {
    font-size: 12px;
    border: 1px solid lightgray;
    text-align: center;

    tr {
        vertical-align: middle;
    }

    .btn-ctrl {
        font-size: 12px;
    }
}

.input-date-cell {
    height: 100%;
    background-color: none;
    margin: 0;
}   

.input-date-cell::-webkit-inner-spin-button,
.input-date-cell::-webkit-outer-spin-button{
    display: none;
}