.ManageTable {
    tr {
        vertical-align: middle;
        font-size: small;
    }

    .student {
        background-color: antiquewhite;
    }

    .teacher {
        background-color: lightblue;
    }
    
    @mixin cursor-pointer() {
        cursor: pointer;
    }

    .toggle-pointer {
        @include cursor-pointer()
    }

    .not-allow {
        @include cursor-pointer();
        background-color: Orchid;

        &:hover {
            background-color: rgb(255, 134, 251);
        }
        
        &:active {
            background-color: rgb(255, 177, 252);
        }
    }

    .allow {
        @include cursor-pointer();
        background-color: YellowGreen;

        &:hover {
            background-color: rgb(192, 255, 66);
        }
        
        &:active {
            background-color: rgb(213, 255, 128);
        }
    }

    .copy {
        @include cursor-pointer();
        opacity: 0;
        color: grey;

        &:hover {
            opacity: 1;
        }

        &:active {
            opacity: 1;
            color: black;
        }
    }
}